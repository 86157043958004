import { render, staticRenderFns } from "./HealthyStep.vue?vue&type=template&id=0118c23b&scoped=true"
import script from "./HealthyStep.vue?vue&type=script&lang=js"
export * from "./HealthyStep.vue?vue&type=script&lang=js"
import style0 from "./HealthyStep.vue?vue&type=style&index=0&id=0118c23b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0118c23b",
  null
  
)

export default component.exports